import { StreamActions } from '@hotwired/turbo'

StreamActions.visit = function () {
  const location = this.getAttribute('location')
  const turboAction = this.getAttribute('turbo-action')
  const frameId = this.getAttribute('frame-id')

  const options = {}
  if (turboAction) options.action = turboAction
  if (frameId) options.frame = frameId

  window.Turbo.visit(location, options)
}
