import { StreamActions } from '@hotwired/turbo'

StreamActions.replace_or_after = function () {
  const replaceTarget = document.getElementById(this.target)

  if (replaceTarget) {
    replaceTarget.replaceWith(this.templateContent)
  } else {
    const afterTarget = document.getElementById(this.getAttribute('after_target'))
    if (afterTarget) {
      afterTarget.parentElement?.insertBefore(this.templateContent, afterTarget.nextSibling)
    }
  }
}
