import ApplicationController from './application_controller'
import BulmaTagsInput from '@creativebulma/bulma-tagsinput'

export default class MultiSelectController extends ApplicationController {
  static targets = ['input']

  connect () {
    super.connect()

    this.tagInput = new BulmaTagsInput(this.inputTarget, {
      freeInput: false
    })
  }

  disconnect () {
    this.tagInput.destroy()
    this.tagInput = null
    super.disconnect()
  }
}
