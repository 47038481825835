import ApplicationController from './application_controller'

export default class NavbarController extends ApplicationController {
  static targets = ['menu']

  toggleActive ({ target }) {
    if (target.classList.contains('is-active')) {
      target.classList.remove('is-active')
      this.menuTarget.classList.remove('is-active')
    } else {
      target.classList.add('is-active')
      this.menuTarget.classList.add('is-active')
    }
  }
}
