import ApplicationController from './application_controller'
import BulmaTagsInput from '@creativebulma/bulma-tagsinput'
import toMaterialStyle from 'material-color-hash'

export default class TagSelectController extends ApplicationController {
  static targets = ['input']

  connect () {
    super.connect()

    this.fetchTags().then(tags => {
      this.tagInput = new BulmaTagsInput(this.inputTarget, {
        source: tags
      })

      this.element.querySelectorAll('span.tag').forEach(node => this.updateTag(node))

      const mutationHandler = (mutationList, observer) => {
        for (const mutation of mutationList) {
          mutation.addedNodes.forEach(node => this.updateTag(node))
        }
      }

      this.mutationObserver = new MutationObserver(mutationHandler)
      this.mutationObserver.observe(this.element, { childList: true, subtree: true })
    })
  }

  async fetchTags (value = null) {
    const baseUrl = `${window.location.protocol}//${window.location.host}`
    const params = new URLSearchParams()
    if (value) {
      params.set('name', value)
    }
    const url = new URL(`/tags.json?${params.toString()}`, baseUrl)
    return await fetch(url.toString()).then(response => response.json().then(json => json.tags))
  }

  updateTag (tag) {
    if (!(tag instanceof HTMLElement)) return
    if (!tag.classList.contains('tag')) return

    const hashingText = tag.innerText
    const style = toMaterialStyle(hashingText, 300)

    tag.style.backgroundColor = style.backgroundColor
    tag.style.color = style.color
  }

  disconnect () {
    this.mutationObserver.disconnect()
    this.mutationObserver = null
    this.tagInput.destroy()
    this.tagInput = null
    super.disconnect()
  }
}
