import ApplicationController from './application_controller'
import { compact } from 'lodash'

export default class TableSelectionController extends ApplicationController {
  static targets = ['row', 'ids', 'selectAll', 'id']
  idsSet = new Set()

  connect () {
    super.connect()
    this.loadIdsFromForm()

    if (!(this.hasIdsTarget ^ this.hasIdTarget)) {
      throw new Error('Either the ids target or the id target must be set. Not both.')
    }
  }

  rowTargetConnected (target) {
    this.setCheckboxState(target)
  }

  updateSelectAll () {
    if (this.selectAllTarget.checked) {
      for (const row of this.rowTargets) {
        this.idsSet.add(parseInt(row.value))
        row.checked = true
      }
    } else {
      for (const row of this.rowTargets) {
        this.idsSet.delete(parseInt(row.value))
        row.checked = false
      }
    }
    this.serializeIdsToForm()
  }

  setSelectAllState () {
    if (this.hasIdTarget) return

    for (const row of this.rowTargets) {
      if (!this.idsSet.has(parseInt(row.value))) {
        this.selectAllTarget.checked = false
        return
      }
    }
    this.selectAllTarget.checked = true
  }

  updateRowSelect (event) {
    const id = parseInt(event.target.value)
    if (this.hasIdsTarget) {
      if (event.target.checked) {
        this.idsSet.add(id)
      } else {
        this.idsSet.delete(id)
      }
    } else {
      if (event.target.checked) {
        this.idsSet = new Set([id])
      }
    }

    this.setSelectAllState()
    this.serializeIdsToForm()
  }

  loadIdsFromForm () {
    if (this.hasIdsTarget) {
      const ids = compact(this.idsTarget.value.split(',').map((value) => parseInt(value)))
      this.idsSet = new Set(ids)
      return
    }

    if (this.idTarget.length === 0) {
      this.idsSet = new Set()
    } else {
      this.idsSet = new Set([parseInt(this.idTarget.value)])
    }
  }

  setCheckboxState (checkbox) {
    checkbox.checked = this.idsSet.has(parseInt(checkbox.value))
    this.setSelectAllState()
  }

  serializeIdsToForm () {
    if (this.hasIdsTarget) {
      this.serializeManyIdsToForm()
    } else {
      this.serializeOneIdToForm()
    }
  }

  serializeManyIdsToForm () {
    const previous = this.idsTarget.value
    this.idsTarget.value = [...this.idsSet].join(',')
    if (previous !== this.idsTarget.value) {
      this.idsTarget.dispatchEvent(new Event('change'))
    }
  }

  serializeOneIdToForm () {
    const previous = this.idTarget.value
    this.idTarget.value = [...this.idsSet][0]

    if (previous !== this.idTarget.value) {
      this.idTarget.dispatchEvent(new Event('change'))
    }
  }
}
