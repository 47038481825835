import ApplicationController from './application_controller'

export default class DropdownController extends ApplicationController {
  static classes = ['active']
  static targets = ['content']

  toggle (event) {
    event.stopPropagation()
    this.element.classList.toggle(this.activeClass)
  }

  hide (event) {
    event.stopPropagation()
    this.element.classList.remove(this.activeClass)
  }

  show (event) {
    event.stopPropagation()
    this.element.classList.add(this.activeClass)
  }
}
