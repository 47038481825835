import ApplicationController from './application_controller'

export default class ModalController extends ApplicationController {
  static targets = ['modal']

  close (event) {
    if (!this.hasModalTarget) return

    this.modalTarget.classList.remove('is-active')
    this.modalTarget.innerHTML = ''
  }
}
