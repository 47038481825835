import ApplicationController from './application_controller'

export default class RadioButtonToggleVisibilityController extends ApplicationController {
  static targets = ['radioButton', 'section']
  static classes = ['hidden']

  connect () {
    super.connect()
    this.toggleVisibility()
  }

  toggleVisibility () {
    for (const radioButton of this.radioButtonTargets) {
      this.toggleVisibilityByRadioButton(radioButton)
    }
  }

  toggleVisibilityByRadioButtonEvent (event) {
    this.toggleVisibilityByRadioButton(event.target)
  }

  toggleVisibilityByRadioButton (radioButton) {
    if (!radioButton.checked) {
      return
    }

    for (const element of this.sectionTargets) {
      // Get values from section element, each value should be one of radio buttons' value,
      // it represent which radio button is controlling which section visibility.
      const values = element.dataset.radioButtonValue.split(' ')

      // Radio button is checked, matching value section element should be visible.
      if (values.includes(radioButton.value)) {
        element.classList.remove(this.hiddenClass)
      // Radio button is unchecked, unmatching value section element should be hidden.
      } else {
        element.classList.add(this.hiddenClass)
      }
    }
  }
}
