import ApplicationController from './application_controller'

export default class CopySubmitController extends ApplicationController {
  static targets = ['form', 'field']

  update (event) {
    this.fieldTarget.value = event.target.value
    this.formTarget.requestSubmit()
  }
}
