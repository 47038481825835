import ApplicationController from './application_controller'
import toMaterialStyle from 'material-color-hash'

export default class TagController extends ApplicationController {
  connect () {
    super.connect()

    const hashingText = this.element.innerText
    const style = toMaterialStyle(hashingText, 300)

    this.element.style.backgroundColor = style.backgroundColor
    this.element.style.color = style.color
  }
}
