import ApplicationController from './application_controller'

// The controller is responsible for managing forms for queuing bulk jobs.
// Copies values from form elements back and forth between the filter from and
// the form for the submission of bulk jobs.
export default class FilterSelectionController extends ApplicationController {
  static targets = ['source', 'config', 'configMode', 'sourceMode']

  sourceTargetConnected (target) {
    this.setSourceState(target)
  }

  sourceModeTargetConnected (target) {
    this.configModeTarget.value = target.value
  }

  setSourceState (sourceTarget) {
    const sourceInput = this.findInput(sourceTarget)
    const sourcePath = this.parsePath(sourceInput.name).findLast(() => true)
    const configTarget = this.configTargets.find(configTarget => {
      const configPath = this.parsePath(configTarget.name).findLast(() => true)
      return sourcePath === configPath
    })

    if (sourceInput.type === 'radio') {
      sourceInput.checked = configTarget.value === sourceInput.value
    } else {
      sourceInput.value = configTarget.value
    }
  }

  setConfigStateFromSource (fromTarget) {
    const fromInput = this.findInput(fromTarget)
    const fromPath = this.parsePath(fromInput.name).findLast(() => true)
    const configTarget = this.configTargets.find(configTarget => {
      const input = this.findInput(configTarget)
      const configPath = this.parsePath(input.name).findLast(() => true)
      return fromPath === configPath
    })

    if (fromInput.type === 'radio') {
      if (fromInput.checked) configTarget.value = fromInput.value
    } else {
      configTarget.value = fromInput.value
    }
  }

  clearFilter () {
    this.configTargets.forEach(target => {
      target.value = ''
    })
  }

  updateFilter () {
    this.sourceTargets.forEach(target => this.setConfigStateFromSource(target))
  }

  parsePath (pathString) {
    return pathString.split(/[[\]]/).filter(str => str.length)
  }

  findInput (target) {
    const selector = 'input[name],select[name],textarea[name]'
    // Accommodates the tag selector.
    // We can't directly attach a target to the hidden field for the tag selector.
    return target.matches(selector) ? target : target.querySelector(selector)
  }
}
