import ApplicationController from './application_controller'

export default class DisableController extends ApplicationController {
  static targets = ['disable']

  disable () {
    this.disableTargets.forEach(target => {
      target.disabled = true
    })
  }

  enable () {
    this.disableTargets.forEach(target => {
      target.disabled = false
    })
  }
}
