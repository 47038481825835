import ApplicationController from './application_controller'

export default class ArrayFieldController extends ApplicationController {
  removeItem (event) {
    event.currentTarget.closest('.field').remove()
  }

  addItem (event) {
    const button = event.currentTarget
    const newField = button.closest('.field').cloneNode(true)
    const input = newField.querySelector('input')

    if (input) {
      input.value = null
    }

    this.element.appendChild(newField)
    button.firstElementChild.classList.remove('fa-plus')
    button.firstElementChild.classList.add('fa-minus')
    button.dataset.action = 'array-field#removeItem'
    this.element.lastElementChild.querySelector('input').focus()
  }
}
