import { Controller } from '@hotwired/stimulus'

export default class ApplicationController extends Controller {
  connect () {
    this.element.controllers ||= {}
    this.element.controllers[this.identifier] = this
  }

  disconnect () {
    this.element.controllers ||= {}
    delete this.element.controllers[this.identifier]
  }
}
