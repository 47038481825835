import ApplicationController from './application_controller'

export default class TemplatableController extends ApplicationController {
  static targets = ['item', 'template', 'items']
  static values = { pathKey: String }

  append (event) {
    const clone = this.templateTarget.content.cloneNode(true)
    const id = this.maxItem() + 1
    clone.firstElementChild.dataset.id = id
    clone.querySelectorAll('[name]').forEach((el) => {
      el.name = el.name.replaceAll(this.pathKeyValue, id)
      el.id = el.id.replaceAll(this.pathKeyValue, id)
    })

    clone.querySelectorAll('[for]').forEach((el) => {
      el.setAttribute('for', el.getAttribute('for').replaceAll(this.pathKeyValue, id))
    })

    this.itemsTarget.append(clone)
    event.stopPropagation()
  }

  delete (event) {
    const parent = this.itemTargets.find((item) => item.contains(event.target))

    const destroyField = [...parent.children].find(element => element.getAttribute('name')?.endsWith('[_destroy]'))

    if (destroyField) {
      // This field managed by Rail's nested_attributes_for,
      // and deletion requires passing the object with the [_destroy] key set to 1.
      parent.classList.add('is-hidden')
      destroyField.value = '1'
    } else {
      // This attribute can be deleted by omission.
      parent.remove()
    }

    event.stopPropagation()
  }

  maxItem () {
    return this.itemTargets
      .reduce(
        (acc, item) => parseInt(item.dataset.id) > acc ? parseInt(item.dataset.id) : acc,
        -1
      )
  }
}
